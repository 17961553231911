//Import
@import "variables";
@import "typography";

.page-header-title
{
  font-family: $main-headings;
  color: $main-color;
}

.generic-breadcrumb
{
  li a
  {
    font-family: $main-sub-headings;
  }
}

.breadcrumb > .active
{
  font-family: $main-sub-headings;
}

.bootstrap-select > .btn
{
  border-bottom: 1.2px solid $main-color !important;
  font-family: $main-inputs;
}

.dropdown-menu > li > a
{
  font-family: $main-inputs;
}

.generic-label
{
  font-family: $main-labels;
  color: $main-color;
}

.genericInput
{
  font-family: $main-labels;
  border-bottom: 1.2px solid $main-color;

  &:focus
  {
    border-color: $system-color;
  }
}

.modalButton
{
  font-family: $main-inputs;
}

.generic-textbox
{
  font-family: $main-labels;
  font-size: 14px;
  background-color: transparent;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1.2px solid $main-color;
  border-radius: 0px;
  margin-bottom: 12px;

  &:focus
  {
    border-color: $system-color;
  }
}

blockquote
{
  margin-top: 15px!important;
  margin-bottom: 15px!important;
  font-size: 14px;
}

.centered {
  text-align: center;
  font-size: 0;
}
.centered > div {
  float: none;
  display: inline-block;
  text-align: left;
  font-size: 13px;
}